body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b4f2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #75b4f2;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-ExtraLightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./assets/fonts/Jost-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'),
    url('./assets/fonts/Jost-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}
